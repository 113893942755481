import { useRef, useEffect } from 'react';
import { select } from 'd3-selection';
import { drag as Drag, D3DragEvent } from 'd3-drag';

type Position = {
    x: number;
    y: number;
};

type Props = {
    children: React.ReactNode;
    className?: string;
    position: Position;
    onDragStart?: () => void;
    onDrag(context: DragContext): void;
    onDragEnd?: (context: DragContext) => void;
    onDragMoveStarted?: () => void;
    onDragMoveEnded?: (context: DragContext) => void;
    onClick?: () => void;
};

export type DragContext = {
    dragStartX: number;
    dragStartY: number;
    x: number;
    y: number;
    dx: number;
    dy: number;
    nativeEvent: MouseEvent;
};

export const DraggableSVGGElement = (props: Props) => {
    const ref = useRef<SVGGElement | null>(null);
    const propsRef = useRef<Props>(props);
    propsRef.current = props;

    useEffect(() => {
        const g = ref.current;
        if (!g) {
            return;
        }

        const dragState: {
            dragMoved: boolean;
            dragStartX: number;
            dragStartY: number;
        } = {
            dragMoved: false,
            dragStartX: 0,
            dragStartY: 0,
        };

        const drag = Drag<SVGGElement, unknown, unknown>()
            .on('start', (event: D3DragEvent<SVGGElement, unknown, unknown>) => {
                Object.assign(dragState, {
                    dragMoved: false,
                    dragStartX: event.x,
                    dragStartY: event.y,
                });

                propsRef.current.onDragStart?.();
            })
            .on('drag', (event: D3DragEvent<Element, unknown, unknown>) => {
                if (!dragState.dragMoved) {
                    dragState.dragMoved = true;
                    propsRef.current.onDragMoveStarted?.();
                }

                propsRef.current.onDrag?.({
                    dragStartX: dragState.dragStartX,
                    dragStartY: dragState.dragStartY,
                    x: event.x,
                    y: event.y,
                    dx: event.dx,
                    dy: event.dy,
                    nativeEvent: event.sourceEvent,
                });
            })
            .on('end', (event: D3DragEvent<Element, unknown, unknown>) => {
                const context: DragContext = {
                    dragStartX: dragState.dragStartX,
                    dragStartY: dragState.dragStartY,
                    x: event.x,
                    y: event.y,
                    dx: event.dx,
                    dy: event.dy,
                    nativeEvent: event.sourceEvent,
                };
                if (dragState.dragMoved) {
                    propsRef.current.onDragMoveEnded?.(context);
                }

                propsRef.current.onDragEnd?.(context);
            });

        select(g).call(drag);

        return () => {
            drag.on('drag', null).on('start', null).on('end', null);
            select(g).on('.drag', null);
        };
    }, []);

    return (
        <g
            className={props.className}
            ref={ref}
            transform={`translate(${props.position.x}, ${props.position.y})`}
            onClick={props.onClick}
        >
            {props.children}
        </g>
    );
};
