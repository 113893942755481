import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useActionLogSender } from '@framework/action-log';
import { getClientId } from '@framework/app';
import { container } from '@framework/container';
import { symbols } from '@framework/container/symbols';
import { useViewModelOperationLogSender } from '@model-framework/action-log';
import { CommandHelper, CommandManagerContext, CompositeCommand, useCommandManagerRef } from '@model-framework/command';
import { DisplayOrderRepository } from '@model-framework/display-order/infrastructure';
import { ModelId, ViewId } from '@schema-common/base';
import { useCurrentUserPublicProfile } from '@user/PublicProfile';
import { useSidebarContext } from '@user/Sidebar';
import { ViewModelToolbar } from '@user/pages/ViewModelPage/FloatingUIContainer/ViewModelToolbar';
import { useWriteViewer } from '@view-model/Viewer';
import { ConsistencyLinksOperation, StickyModelContentsOperation } from '@view-model/adapter';
import {
    ApplicationClipboardPayload,
    isViewsClipboardPayload,
    ViewsClipboardPayload,
} from '@view-model/application/clipboard';
import { ViewModelPermissions } from '@view-model/application/context';
import { ClientMouseCursorPubSub } from '@view-model/application/mouse-cursor/ClientMouseCursorPubSub';
import { HuddleMessage, ViewModelNotification } from '@view-model/application/notification';
import { ViewModelPageOperation } from '@view-model/application/operation';
import { WindowEventManager } from '@view-model/application/shortcuts';
import { ModelCollection, StickyModel } from '@view-model/domain/model';
import { ViewCollection, ViewEntity } from '@view-model/domain/view';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { ViewCollectionContents } from '@view-model/domain/view/ViewCollectionContents';
import { StickyModelElementPositionMapRepository } from '@view-model/infrastructure/basic-model/StickyModelElementPositionMapRepository';
import { ModelRepository } from '@view-model/infrastructure/view-model/ModelRepository';
import { Point, Rect } from '@view-model/models/common/basic';
import { LinkCreator, LinkerCanvasView } from '@view-model/models/common/components/LinkCreator';
import { LinkerState } from '@view-model/models/common/components/LinkCreator/LinkerState';
import { Position } from '@view-model/models/common/types/ui';
import { LinkEntityOperation, LinkRepository } from '@view-model/models/sticky/StickyLink';
import { NodeEntityOperation, NodeRepository } from '@view-model/models/sticky/StickyNodeView';
import { StickyZoneEntityOperation, StickyZoneRepository } from '@view-model/models/sticky/StickyZoneView';
import {
    NewElementStyleProvider,
    NewElementStyleProviderContext,
    useNewElementStyleProvider,
} from '@view-model/models/sticky/user-context';
import { Frame, ScaleExtent } from '@view-model/ui/components/Frame';
import { FLAME_SIZE } from '@view-model/ui/components/Frame/Frame';
import { useOpenCloseState, View as ViewView } from '@view-model/ui/components/View';
import {
    ClientMouseCursorView,
    CONSISTENCY_LINK_LINE_WIDTH,
    ConsistencyLinkCollectionView,
    EmptyMessageModal,
    ViewModelPaste,
} from '@view-model/ui/components/ViewModel';
import { WorkspaceEntity } from '@workspace/domain/workspace';
import { ZoomTransform } from 'd3';
import { select } from 'd3-selection';
import { zoom as Zoom, ZoomBehavior, zoomIdentity } from 'd3-zoom';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { FloatingUIContainer } from './FloatingUIContainer';
import { useCreatingComments } from './hooks';
import { useContentViewBox } from './useContentViewBox';
import { StickyModelContentsOperationContextProvider } from '@view-model/ui/components/Model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useHandler, useSnapshot } from '@framework/hooks';
import { VisibleAreaCenterPointProvider } from './VisibleAreaCenterPointProvider';
import { RTDBPath } from '@framework/repository';
import { ViewJSON } from '@schema-app/workspace-contents/{workspaceKey}/view-model-contents/{viewModelId}/views/{viewId}/ViewJSON';
import { PositionSet, PositionSetRepository, PositionSetUpdateCommand } from '@view-model/models/common/PositionSet';
import { useViewModelPageContentShortcuts } from '@user/pages/ViewModelPage/useViewModelPageContentShortcuts';
import { MultiSelectionMode } from './MultiSelectionMode';
import { useAtom } from 'jotai/react';
import { contentTransformAtom } from './contentTransformAtom';
import { ConsistencyLinkCollection, ConsistencyLinkCollectionJSON } from '@view-model/domain/ConsistencyLink';
import { ViewsClipboardOperator } from '@view-model/application/clipboard/ViewsClipboardOperator';
import { PopupMenuTeleporter, ViewTitleBarTeleporter } from './teleporters';
import { ViewsDeleteConfirmModal } from '@view-model/ui/components/View/ViewsDeleteConfirmModal';
import { viewGridLayout } from '@view-model/ui/components/View/constants';
import { ViewContextProvider } from '@view-model/ui/components/View/ViewContextProvider';
import { EditingUsersLoader } from '@view-model/models/sticky/editing-users/EditingUsersLoader';
import { ViewersLoader } from '@view-model/models/sticky/viewers/ViewersLoader';
import { getDefaultStore } from 'jotai';
import { viewersAtom } from '@view-model/models/sticky/viewers/viewersAtom';
import throttle from 'lodash/throttle';
import { DragContext } from '@model-framework/ui';

const MemoizedFloatingUIContainer = memo(FloatingUIContainer);
const MemoizedViewView = memo(ViewView);
const MemoizedViewModelToolbar = memo(ViewModelToolbar);

const LINKER_ICON_OFFSET = 160;
const LINKER_ICON_CIRCLE_SIZE = 128;

type ViewModelPageContentProps = {
    workspace: WorkspaceEntity;
    viewModel: ViewModelEntity;
    viewModelPermissions: ViewModelPermissions;
    otherUserViewBox: Rect | null;
    setOtherUserClientViewBox: (rect: Rect | null) => void;
};

export const ViewModelPageContent: React.FC<ViewModelPageContentProps> = ({
    workspace,
    viewModel,
    viewModelPermissions,
    otherUserViewBox,
    setOtherUserClientViewBox,
}: ViewModelPageContentProps) => {
    const currentUserProfile = useCurrentUserPublicProfile();
    const search = useLocation().search;
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const history = useHistory();

    const viewPositionsRepoRef = useRef(new PositionSetRepository(RTDBPath.View.positionsPath(viewModel.id)));
    const selectedViewsPositionSetRef = useRef(PositionSet.buildEmpty());
    const selectedViewsPreviousPositionSetRef = useRef(PositionSet.buildEmpty());

    const contentViewBox = useContentViewBox();

    const [contentTransform, setContentTransform] = useAtom(contentTransformAtom);
    const actionLogSender = useActionLogSender();
    const commandManagerRef = useCommandManagerRef(viewModel.id, actionLogSender);
    const viewModelOperationLogSender = useViewModelOperationLogSender();
    const operationRef = useRef<ViewModelPageOperation>();

    const newElementStyleProviderRef = useRef(new NewElementStyleProvider());

    const [selectedViewIds, setSelectedViewIds] = useState<Set<ViewId>>(new Set());

    // 表示位置やズームをコントロールする<Frame>コンポーネントへ渡すref
    const frameRef = useRef<SVGGElement | null>(null);
    const [scaleExtent, setScaleExtent] = useState<ScaleExtent>({ min: 0.04, max: 1.5 });

    const [isViewsFirstFetched, setIsViewsFirstFetched] = useState<boolean>(false);
    const [isViewPositionsFirstFetched, setIsViewPositionsFirstFetched] = useState<boolean>(false);

    const pastOtherUserViewBox = useRef<Rect>();
    const windowEventManager = container.get<WindowEventManager>(symbols.WindowEventManager);
    const mouseCursorPubSub = useRef<ClientMouseCursorPubSub>();

    const [viewLoaded, setViewLoaded] = useState<boolean>(false);
    const [selectedConsistencyLinkId, setSelectedConsistencyLinkId] = useState<string | null>(null);
    const [multiSelectionMode, setMultiSelectionMode] = useState<MultiSelectionMode>(MultiSelectionMode.offMode);
    const [showEmptyMessageModal, setShowEmptyMessageModal] = useState<boolean>(false);
    const [linker, setLinker] = useState<LinkerState<ViewId> | null>(null);
    const svgRef = useRef<SVGSVGElement>(null);

    const [models, setModels] = useState<ModelCollection>(viewModel.getModelCollection());
    const [stickyModelContentsOperations, setStickyModelContentsOperations] = useState<
        Record<ModelId, StickyModelContentsOperation>
    >({});
    const styleProvider = useNewElementStyleProvider();
    const { isSidebarVisible } = useSidebarContext();

    const [isOpenDeleteViewsConfirmModal, handleOpenDeleteViewsConfirmModal, handleCloseDeleteViewsConfirmModal] =
        useOpenCloseState();

    // visibleAreaの返り値 rectをメモ化している
    const rect = useMemo(() => {
        return contentViewBox.visibleArea(contentTransform);
    }, [contentViewBox, contentTransform]);

    // ビューモデル画面の閲覧者情報を保存する
    const viewerIconMouseMoveHandler = useWriteViewer(viewModel.id, currentUserProfile, rect);

    useEffect(() => {
        if (!currentUserProfile?.id) return;

        operationRef.current = new ViewModelPageOperation(
            workspace.ownerGroupId,
            currentUserProfile.id,
            commandManagerRef.current,
            viewModel.id,
            viewModelOperationLogSender
        );
    }, [viewModel, commandManagerRef, viewModelOperationLogSender, currentUserProfile?.id, workspace.ownerGroupId]);

    const [viewEntities] = useSnapshot({
        path: RTDBPath.View.viewsPath(viewModel.id),
        load: ({ getChildValues }) => {
            if (!isViewsFirstFetched) {
                setIsViewsFirstFetched(true);
            }

            return Object.values(getChildValues() as Record<string, ViewJSON>).map((json) => ViewEntity.load(json));
        },
    });

    const [viewPositionSet] = useSnapshot({
        path: RTDBPath.View.positionsPath(viewModel.id),
        load({ getChildValues }) {
            if (!isViewPositionsFirstFetched) {
                setIsViewPositionsFirstFetched(true);
            }

            return PositionSet.load(getChildValues() as Record<string, Position> | null);
        },
    });

    const [consistencyLinks] = useSnapshot({
        path: RTDBPath.ConsistencyLink.linksPath(viewModel.id),
        load: ({ getChildValues }) => ConsistencyLinkCollection.load(getChildValues() as ConsistencyLinkCollectionJSON),
    });

    const viewContents = useMemo(() => {
        if (!viewEntities || !viewPositionSet) return null;

        return new ViewCollectionContents(new ViewCollection(viewEntities), viewPositionSet);
    }, [viewPositionSet, viewEntities]);

    const handleDeleteViews = useHandler(async () => {
        if (!viewEntities) return;

        const selectedViews = viewEntities.filter((v) => selectedViewIds.has(v.id));

        const deleteContentsCommands = await Promise.all(
            selectedViews.map(async (v) => {
                const { id, modelId } = v;
                return await CommandHelper.buildDeleteCommandMulti(
                    RTDBPath.Model.modelPath(viewModel.id, modelId),
                    RTDBPath.Model.modelContentPath(viewModel.id, modelId),
                    RTDBPath.View.positionPath(viewModel.id, id),
                    RTDBPath.View.viewPath(viewModel.id, id)
                );
            })
        );

        const targetConsistencyLinks = consistencyLinks?.filterbyViewIds(Array.from(selectedViewIds)) || [];

        const deleteConsistencyLinksCommands = await Promise.all(
            targetConsistencyLinks.map(async (link) => {
                return await CommandHelper.buildDeleteCommand(RTDBPath.ConsistencyLink.linkPath(viewModel.id, link.id));
            })
        );

        const compositeCommand = CompositeCommand.composeOptionalCommands(
            ...deleteContentsCommands.flat(),
            ...deleteConsistencyLinksCommands
        );

        if (compositeCommand) {
            commandManagerRef.current.execute(compositeCommand);
            handleCloseDeleteViewsConfirmModal();

            viewModelOperationLogSender('view:delete', {
                viewId: selectedViews[0].id,
                viewName: selectedViews[0].name.value,
            });
        }
    });

    const handleAddStickyView = useHandler(() => {
        const operation = operationRef.current;
        if (!operation) return;

        const { viewId, modelId, modelType } = operation.addStickyView(contentViewBox.visibleArea(contentTransform));
        viewModelOperationLogSender('view:create', { viewId, modelId, modelType });

        setSelectedViewIds(new Set([viewId]));
    });

    const getViewRects = useHandler((): Record<ViewId, Rect> => {
        if (!viewContents) {
            return {};
        }

        const record: Record<ViewId, Rect> = {};
        for (const view of viewContents.getViews().toArray()) {
            const rect = viewContents.getRectOf(view.id);
            if (rect) {
                record[view.id] = rect;
            }
        }
        return record;
    });

    const viewBoxQuery = useCallback(() => {
        const query = new URLSearchParams();

        const { width, height } = contentViewBox;
        const [x1, y1] = contentTransform.invert([-width / 2, -height / 2]); // 左上
        const [x2, y2] = contentTransform.invert([width / 2, height / 2]); // 右下

        query.set('x1', String(Math.round(x1)));
        query.set('x2', String(Math.round(x2)));
        query.set('y1', String(Math.round(y1)));
        query.set('y2', String(Math.round(y2)));

        return query;
    }, [contentTransform, contentViewBox]);

    const handleGenerateLink = useHandler(() => {
        const query = viewBoxQuery();

        const url = `${window.location.origin}${window.location.pathname}?${query.toString()}`;
        // クリップボードにコピー
        navigator.clipboard
            .writeText(url)
            .then(() => {
                toast('コピーしました | Copied');
            })
            .catch(() => {
                toast.error('コピーに失敗しました | Copy failed');
            });
    });

    const handleOnClickViewModelLink = useHandler(() => {
        const query = viewBoxQuery();
        history.replace({ search: query.toString() });
    });

    const handleChangeContentTransform = useCallback(
        (t: ZoomTransform) => {
            setContentTransform(t);
        },
        [setContentTransform]
    );

    const handlePasteViews = useCallback(
        async (clipboard: ViewsClipboardPayload) => {
            const operation = operationRef.current;
            if (!operation) return;

            const [pastedViewIds, errorMessage] = await operation.pasteViews(
                clipboard,
                contentViewBox.visibleArea(contentTransform).getCenterPoint()
            );

            if (errorMessage) {
                toast.error(errorMessage);
            } else {
                toast.success('ビューをペーストしました');
            }

            setSelectedViewIds(pastedViewIds);
        },
        [contentTransform, contentViewBox]
    );

    const handleDuplicateView = useHandler(handlePasteViews);

    const zoomHandler: ZoomBehavior<SVGGElement, unknown> = useMemo(() => {
        return Zoom<SVGGElement, unknown>()
            .on('zoom', (event) => {
                handleChangeContentTransform(event.transform);
            })
            .scaleExtent([scaleExtent.min, scaleExtent.max]);
    }, [scaleExtent, handleChangeContentTransform]);

    const handleZoomIn = useCallback((): void => {
        if (!frameRef.current) return;

        select(frameRef.current).call(zoomHandler.scaleBy, 1.25);
    }, [zoomHandler.scaleBy]);

    const handleZoomOut = useCallback((): void => {
        if (!frameRef.current) return;

        select(frameRef.current).call(zoomHandler.scaleBy, 0.8);
    }, [zoomHandler.scaleBy]);

    const handleZoomReset = useCallback((): void => {
        if (!frameRef.current) return;
        if (!viewContents) return;

        const bounds = viewContents.getBounds();
        if (!bounds) return;

        const visibleZone = bounds.applyMarginKeepingCenter(512);
        const viewBoxRect = Rect.fromSize(contentViewBox.width, contentViewBox.height);
        const scale = viewBoxRect.getSize().calcRatio(visibleZone.getSize());
        const centerPosition = visibleZone.getCenterPoint();

        select(frameRef.current).call(
            zoomHandler.transform,
            zoomIdentity.translate(-1 * centerPosition.x * scale, -1 * centerPosition.y * scale).scale(scale)
        );
    }, [viewContents, contentViewBox.width, contentViewBox.height, zoomHandler.transform]);

    const handleMouseMove = useMemo(
        () =>
            throttle(async (x: number, y: number) => {
                const selfClientId = getClientId();
                const otherViewers = getDefaultStore()
                    .get(viewersAtom)
                    .filter((viewer) => viewer.clientId !== selfClientId);

                // 自分以外がいる場合のみマウスカーソル位置を永続化(同じユーザーでも別タブで開いている場合には永続化)
                if (otherViewers.length > 0) {
                    await mouseCursorPubSub.current?.publish(x, y);
                }

                // マウスカーソルの位置移動がある間は、閲覧者アイコンを保持する
                viewerIconMouseMoveHandler();
            }, 100),
        [viewerIconMouseMoveHandler]
    );

    useEffect(() => {
        // 未ログイン状態であれば、ModelContentsのリスンは行わない
        if (!currentUserProfile) return;

        const modelRepository = new ModelRepository(viewModel.id);
        modelRepository.addListener(
            (model) => {
                setModels(viewModel.addModel(model));

                if (!(model instanceof StickyModel)) return;

                const nodeRepository = new NodeRepository(viewModel.id, model.id);
                const linkRepository = new LinkRepository(viewModel.id, model.id);
                const zoneRepository = new StickyZoneRepository(viewModel.id, model.id);

                const displayOrderRepository = new DisplayOrderRepository(viewModel.id, model.id);
                const nodeEntityOperation = new NodeEntityOperation(nodeRepository);
                const linkEntityOperation = new LinkEntityOperation(linkRepository);
                const zoneEntityOperation = new StickyZoneEntityOperation(model, zoneRepository);

                const positionMapRepository = new StickyModelElementPositionMapRepository(viewModel.id, model.id);

                const operation = new StickyModelContentsOperation(
                    workspace.ownerGroupId,
                    viewModel,
                    model,
                    currentUserProfile.id,
                    nodeRepository,
                    linkRepository,
                    zoneRepository,
                    commandManagerRef.current,
                    nodeEntityOperation,
                    linkEntityOperation,
                    zoneEntityOperation,
                    positionMapRepository,
                    displayOrderRepository,
                    styleProvider
                );

                setStickyModelContentsOperations((state) => {
                    return { ...state, [model.id]: operation };
                });
            },
            (model) => setModels(viewModel.updateModel(model)),
            (model) => {
                setModels(viewModel.removeModel(model.key));
                setStickyModelContentsOperations((state) => {
                    const { [model.id]: _removed, ...newState } = state;
                    return newState;
                });
            }
        );
        return () => {
            modelRepository.removeListener();
            setModels(viewModel.resetModels());
            setStickyModelContentsOperations({});
        };
    }, [currentUserProfile, commandManagerRef, styleProvider, viewModel, workspace.ownerGroupId]);

    // frameRefへのzoomHandlerの適用
    useEffect(() => {
        const target = frameRef.current;
        if (!target) return;

        select(target).call(zoomHandler);

        return () => {
            select(target).on('zoom', null);
        };
        /*
            本来はFrameにcallbackRefを渡して変更を伝播させるのがベストだが、
            FrameのRefのtypeがLegacyRefでcallbackRefを受け取れないためcurrentを監視させる
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoomHandler, frameRef.current]);

    useEffect(() => {
        if (!currentUserProfile) return;

        const pubSub = new ClientMouseCursorPubSub(getClientId(), currentUserProfile, viewModel.id);
        mouseCursorPubSub.current = pubSub;

        return () => pubSub.unsubscribe();
    }, [currentUserProfile, viewModel.id]);

    useViewModelPageContentShortcuts({
        commandManagerRef,
        viewModel,
        selectedViewIds,
        setMultiSelectionMode,
        selectedConsistencyLinkId,
        stickyModelContentsOperations,
    });

    useEffect(() => {
        windowEventManager.onMounted((windowActive: boolean) => {
            // ウィンドウがアクティブでなくなった場合には、 ShiftKey 押下中状態を解除する
            if (!windowActive) {
                setMultiSelectionMode(MultiSelectionMode.offMode);
            }
        });
        return () => windowEventManager.onWillUnmount();
    }, [windowEventManager]);

    useEffect(() => {
        const unsubscribe = windowEventManager.listenOnPaste(
            async (payload: ApplicationClipboardPayload): Promise<void> => {
                // 編集できない場合には、貼り付け処理を無視する
                if (!viewModelPermissions.isContentEditable) return;
                if (!payload) return;

                if (isViewsClipboardPayload(payload)) {
                    handlePasteViews(payload);
                }
            }
        );
        return () => unsubscribe();
    }, [handlePasteViews, viewModelPermissions.isContentEditable, windowEventManager]);

    useEffect(() => {
        const unsubscribe = windowEventManager.listenOnCopy(async (): Promise<void> => {
            if (selectedViewIds.size <= 0 || !viewContents) return;

            if (Object.values(stickyModelContentsOperations).some((o) => o.hasSelectedElements())) return;

            const selectedViews = viewContents.getViewsByIds(Array.from(selectedViewIds));
            const selectedViewPositions = viewContents.getPositionsByIds(Array.from(selectedViewIds));

            const success = await ViewsClipboardOperator.copy(
                viewModel.id,
                selectedViews,
                selectedViewPositions,
                consistencyLinks,
                viewModelOperationLogSender
            );

            if (success) {
                toast.success('コピーしました | Copied');
            } else {
                toast.error('コピーに失敗しました | Copy failed');
            }
        });

        return () => unsubscribe();
    }, [
        viewModelOperationLogSender,
        windowEventManager,
        selectedViewIds,
        viewContents,
        viewModel.id,
        consistencyLinks,
        stickyModelContentsOperations,
    ]);

    const getVisibleZone = useCallback((): Rect => {
        // クエリパラメータで表示範囲が数値で正しく指定されていれば、その範囲を返す
        const [x1, y1] = [Number(query.get('x1')), Number(query.get('y1'))];
        const [x2, y2] = [Number(query.get('x2')), Number(query.get('y2'))];
        if (x1 && y1 && x2 && y2) {
            return Rect.fromLTRB(x1, y1, x2, y2);
        }
        if (viewContents) {
            const viewId = query.get('targetView');
            if (viewId) {
                const targetView = viewContents.findView(viewId);
                if (targetView) setSelectedViewIds(new Set([targetView.id]));
                const viewRect = viewContents.getRectOf(viewId);
                if (viewRect) return viewRect.applyMarginKeepingCenter(512);
            }

            // ビューが存在するならば、それらのビューが全て表示されるように範囲を返す
            const bounds = viewContents.getBounds();
            if (bounds !== null) {
                return bounds.applyMarginKeepingCenter(512);
            }
        }

        return Rect.fromSize(512 * 7, 512 * 5);
    }, [query, viewContents, setSelectedViewIds]);

    // ビューモデル内遷移でクエリパラメータが変わった場合は表示位置調整が走るようにviewLoadedフラグをオフにする。
    // フラグの名前は見直した方がいいかもしれない。
    useEffect(() => {
        setViewLoaded(false);
    }, [query]);

    useEffect(() => {
        if (!frameRef.current) return;

        // 初回未取得ならばスキップ
        if (!isViewsFirstFetched || !isViewPositionsFirstFetched) return;

        // 初回描画済みならば、表示範囲に合わせての自動リサイズをスキップ
        if (viewLoaded) return;

        // 既存のビュー全てが表示範囲に含まれるように適切にリサイズする
        // ビューが 0 個の時には、標準サイズのビューが良い感じに収まるように調整しておく
        const visibleZone = getVisibleZone();
        const viewBoxRect = Rect.fromSize(contentViewBox.width, contentViewBox.height);
        const scale = viewBoxRect.getSize().calcRatio(visibleZone.getSize());
        const centerPosition = visibleZone.getCenterPoint();

        select(frameRef.current).call(
            zoomHandler.transform,
            zoomIdentity.translate(-1 * centerPosition.x * scale, -1 * centerPosition.y * scale).scale(scale)
        );

        // 初期の scaleExtent よりも縮小表示が必要な場合には scaleExtent を再設定する
        if (scale < scaleExtent.min) {
            setScaleExtent({ min: scale * 0.8, max: scaleExtent.max });
        }
        if (!viewContents?.isEmpty()) {
            setViewLoaded(true);
            setShowEmptyMessageModal(false);
            return;
        }

        // viewContentsがまだ読み込まれていない時と何もない時を区別
        // viewContentsが何もないことが確定した時にのみEmptyMessageModalが表示されるようにする
        setShowEmptyMessageModal(true);
    }, [
        viewModel,
        contentViewBox,
        scaleExtent,
        viewLoaded,
        zoomHandler.transform,
        getVisibleZone,
        frameRef,
        setScaleExtent,
        viewContents,
        isViewsFirstFetched,
        isViewPositionsFirstFetched,
    ]);

    // otherUserViewBoxにセットされた他の閲覧中ユーザーの閲覧範囲へ移動する
    useEffect(() => {
        if (!frameRef.current) return;
        if (!otherUserViewBox || pastOtherUserViewBox.current == otherUserViewBox) return;
        const viewBoxRect = Rect.fromSize(contentViewBox.width, contentViewBox.height);
        const scale = viewBoxRect.getSize().calcRatio(otherUserViewBox.getSize());
        const centerPosition = otherUserViewBox.getCenterPoint();

        select(frameRef.current).call(
            zoomHandler.transform,
            zoomIdentity.translate(-1 * centerPosition.x * scale, -1 * centerPosition.y * scale).scale(scale)
        );
        // 初期の scaleExtent よりも縮小表示が必要な場合には scaleExtent を再設定する
        if (scale < scaleExtent.min) {
            setScaleExtent({ min: scale * 0.8, max: scaleExtent.max });
        }
        toast('ユーザの位置に移動しました | Moved to the destination');
        pastOtherUserViewBox.current = otherUserViewBox;
    }, [otherUserViewBox, scaleExtent, contentViewBox, viewModel, zoomHandler.transform, frameRef, setScaleExtent]);

    const focusView = useCallback(
        (viewId: string) => {
            if (!frameRef.current || !viewContents) return;

            const viewRect = viewContents.getRectOf(viewId)?.applyMarginKeepingCenter(512);
            if (!viewRect) return;

            const viewBoxRect = Rect.fromSize(contentViewBox.width, contentViewBox.height);
            const scale = viewBoxRect.getSize().calcRatio(viewRect.getSize());
            const centerPosition = viewRect.getCenterPoint();

            select(frameRef.current).call(
                zoomHandler.transform,
                zoomIdentity.translate(-1 * centerPosition.x * scale, -1 * centerPosition.y * scale).scale(scale)
            );
        },
        [frameRef, contentViewBox, viewContents, zoomHandler.transform]
    );

    const handleHuddleMessage = useCallback(
        (message: HuddleMessage) => {
            focusView(message.viewId);
            if (!message.isOwnMessage(currentUserProfile?.id)) {
                toast(`${message.getCallerName()}さんに集められました | Assembled`);
            }
        },
        [currentUserProfile, focusView]
    );

    // ビューへの集合通知購読
    useEffect(() => {
        const channels = new ViewModelNotification(viewModel.id);
        const channel = channels.huddleChannel();

        channel.subscribe(handleHuddleMessage);

        return () => channel.unsubscribe();
    }, [handleHuddleMessage, viewModel.id]);

    const handleSelectConsistencyLink = useCallback(
        (id: string | null) => {
            setSelectedConsistencyLinkId(id);
            setSelectedViewIds(new Set());
        },
        [setSelectedViewIds]
    );

    const handleSelectSingleView = useCallback(
        (viewId: ViewId): void => {
            setSelectedViewIds(new Set([viewId]));
            setSelectedConsistencyLinkId(null);
        },
        [setSelectedViewIds]
    );

    const handleToggleSelectedViews = useCallback((viewId: ViewId) => {
        setSelectedViewIds((prev) => {
            const newSelectedViewIds = new Set(prev);
            if (newSelectedViewIds.has(viewId)) {
                newSelectedViewIds.delete(viewId);
            } else {
                newSelectedViewIds.add(viewId);
            }

            return newSelectedViewIds;
        });
    }, []);

    const handleDeselectAllViews = useCallback(() => {
        setSelectedViewIds(new Set());
    }, []);

    const deselection = useCallback((): void => {
        setSelectedViewIds(new Set());
        setSelectedConsistencyLinkId(null);
    }, [setSelectedViewIds]);

    const orderedViews = (viewContents ? viewContents : ViewCollectionContents.buildEmpty())
        .getViews()
        .bringSelectedToFront(selectedViewIds);

    const handleLinkerStart = useCallback(
        (startPosition: Position): void => {
            if (selectedViewIds.size !== 1) return;
            const selectedViewId = Array.from(selectedViewIds)[0];

            setLinker(LinkerState.creatingStart(selectedViewId, startPosition));
        },
        [selectedViewIds]
    );

    const handleLinkerMove = useCallback(
        (currentPosition: Position): void => {
            if (!linker || !linker.source || !viewContents) {
                return;
            }

            // currentPositionはLinkCreatorの属する要素（整合性リンク作成開始したビュー）の左上座標からの相対座標なので
            // それを補正した上で対象のビューを探す
            const sourcePosition = viewContents?.findPosition(linker.source);
            if (!sourcePosition) return;

            const linkerPosition = Point.fromPosition(currentPosition).addXY(sourcePosition.x, sourcePosition.y);

            const foundView = viewContents.findForegroundViewByPosition(linkerPosition);
            setLinker(linker.move(foundView?.id || null, currentPosition));
        },
        [linker, viewContents]
    );

    const handleLinkerEnd = useCallback((): void => {
        if (!linker || !viewContents) {
            return;
        }
        const sourceViewId = linker.source;
        const targetViewId = linker.target;
        setLinker(null);

        if (!sourceViewId || !targetViewId) {
            return;
        }
        if (sourceViewId === targetViewId) {
            return;
        }
        const sourceView = viewContents.findView(sourceViewId);
        const targetView = viewContents.findView(targetViewId);
        if (!sourceView || !targetView) {
            return;
        }

        ConsistencyLinksOperation.handleCreate({
            fromViewKey: sourceView.key,
            toViewKey: targetView.key,
            commandManager: commandManagerRef.current,
            viewModelId: viewModel.id,
        });
    }, [linker, viewContents, commandManagerRef, viewModel.id]);

    const handleDragViewsStart = useHandler((viewId: ViewId) => {
        if (!viewPositionSet) return;

        // 選択されているビューがある状態でも別の未選択のビューを移動することを可能にするために
        // ドラッグ開始時にどのビューのタイトルバーが押下されたかに応じて更新するpositionSetを決める
        // 選択されているビューのうちの１つのタイトルバーを掴む→選択されているビュー全体を移動
        // 選択されていないビューのタイトルバーを掴む→そのビューのみを移動
        const targetPositionSet = viewPositionSet.getMulti(
            selectedViewIds.has(viewId) ? Array.from(selectedViewIds) : [viewId]
        );

        selectedViewsPositionSetRef.current = targetPositionSet;
        selectedViewsPreviousPositionSetRef.current = targetPositionSet;
    });

    const handleDragViews = useHandler((context: DragContext) => {
        const repo = viewPositionsRepoRef.current;
        const selectedViewsPositionSet = selectedViewsPreviousPositionSetRef.current;
        const movedPositionSet = selectedViewsPositionSet.moveAll(
            context.x - context.dragStartX,
            context.y - context.dragStartY
        );

        repo.saveMany(movedPositionSet).then();
        selectedViewsPositionSetRef.current = movedPositionSet;
    });

    const handleDragViewsEnd = useHandler(() => {
        const repo = viewPositionsRepoRef.current;
        const previousPositionSet = selectedViewsPreviousPositionSetRef.current;
        const selectedViewsPositionSet = selectedViewsPositionSetRef.current;

        const commandManager = commandManagerRef.current;

        const snappedPositionSet = selectedViewsPositionSet.snapToLayout(viewGridLayout);

        // コマンド実行（previousと動かしてるやつ）
        const command = new PositionSetUpdateCommand(previousPositionSet, snappedPositionSet, repo);

        if (previousPositionSet.isEqual(snappedPositionSet)) {
            command.do();
        } else {
            commandManager.execute(command);
        }

        // positionSetRefを初期化
        selectedViewsPositionSetRef.current = PositionSet.buildEmpty();
        selectedViewsPreviousPositionSetRef.current = PositionSet.buildEmpty();
    });

    const {
        creatingComments,
        handleCreatingCommentAdd,
        handleCreatingCommentDrag,
        handleCreatingCommentDragEnd,
        handleCreatingCommentCancel,
        handleCreatingCommentSubmit,
    } = useCreatingComments(stickyModelContentsOperations);

    // 整合性リンク作成アイコンの表示位置のための変数
    const selectedViewPosition =
        (selectedViewIds.size === 1 && viewContents?.findPosition(Array.from(selectedViewIds)[0])) || null;
    const selectedViewSize =
        (selectedViewIds.size === 1 && viewContents?.findView(Array.from(selectedViewIds)[0])?.size) || null;

    return (
        <Fragment>
            <VisibleAreaCenterPointProvider
                visibleAreaCenterPoint={contentViewBox.visibleArea(contentTransform).getCenterPoint()}
            >
                <CommandManagerContext.Provider value={commandManagerRef.current}>
                    <NewElementStyleProviderContext.Provider value={newElementStyleProviderRef.current}>
                        {/* ビューモデル全体に対するペースト操作をハンドリングするコンポーネント */}
                        {viewModelPermissions.isContentEditable && (
                            <ViewModelPaste
                                groupId={workspace.ownerGroupId}
                                viewModelId={viewModel.id}
                                onSelectViewIds={setSelectedViewIds}
                            />
                        )}

                        <div className="flex flex-row">
                            {commandManagerRef.current && (
                                <div className="relative min-w-0">
                                    <MemoizedFloatingUIContainer
                                        setClientViewBox={setOtherUserClientViewBox}
                                        onZoomIn={handleZoomIn}
                                        onZoomOut={handleZoomOut}
                                        onZoomReset={handleZoomReset}
                                        workspace={workspace}
                                        viewModel={viewModel}
                                        views={viewContents ? viewContents.getViews() : ViewCollection.buildEmpty()}
                                        viewModelPermissions={viewModelPermissions}
                                        onGenerateLink={handleGenerateLink}
                                    />

                                    <svg
                                        pointerEvents="all"
                                        // 閉じるときにtransitionが有効だとviewBoxも影響を受けて拡大アニメーションが発火してしまう
                                        style={{
                                            transition: isSidebarVisible ? 'width 0.5s' : '',
                                        }}
                                        width={contentViewBox.width}
                                        height={contentViewBox.height}
                                        viewBox={contentViewBox.toSVGAttribute()}
                                        ref={svgRef}
                                    >
                                        <Frame
                                            frameRef={frameRef}
                                            onMouseMove={handleMouseMove}
                                            transform={contentTransform}
                                            deselection={deselection}
                                        >
                                            {/* 整合性リンク */}
                                            <ConsistencyLinkCollectionView
                                                viewContents={
                                                    viewContents ? viewContents : ViewCollectionContents.buildEmpty()
                                                }
                                                selectedId={selectedConsistencyLinkId}
                                                onSelect={handleSelectConsistencyLink}
                                                consistencyLinks={consistencyLinks}
                                            />

                                            {viewModelPermissions.isContentEditable &&
                                                selectedViewPosition &&
                                                selectedViewSize && (
                                                    /* LinkCreator は対象要素の左上が原点になっている前提で表示位置計算しているため g要素で囲む */
                                                    <g transform={selectedViewPosition.toSVGTranslate()}>
                                                        <LinkCreator
                                                            elementSize={selectedViewSize}
                                                            directions={['down', 'left', 'right']}
                                                            iconOffset={LINKER_ICON_OFFSET}
                                                            iconCircleSize={LINKER_ICON_CIRCLE_SIZE}
                                                            onLinkerStart={handleLinkerStart}
                                                            onLinkerMove={handleLinkerMove}
                                                            onLinkerEnd={handleLinkerEnd}
                                                        />
                                                    </g>
                                                )}

                                            {orderedViews.map((view) => {
                                                // ひとまずモデルなしの空のビューは許容しない
                                                const model = models.get(view.modelKey);
                                                if (!model) return null;

                                                const modelCommentThreadId =
                                                    query.get('targetView') === view.id ? query.get('threadId') : null;

                                                // 未ログインの状態ではビューをレンダリングしない
                                                if (!currentUserProfile) return null;

                                                const viewPosition = viewContents?.findPosition(view.id);
                                                if (!viewPosition) return null;

                                                const isLinkableTarget = linker?.target === view.id;

                                                const isSelected = selectedViewIds.has(view.id);

                                                return (
                                                    <ViewContextProvider
                                                        key={view.id}
                                                        viewModelId={viewModel.id}
                                                        viewId={view.id}
                                                        modelId={view.modelId}
                                                        viewPosition={viewPosition}
                                                    >
                                                        <StickyModelContentsOperationContextProvider
                                                            operation={stickyModelContentsOperations[model.id]}
                                                        >
                                                            <MemoizedViewView
                                                                key={view.key.toString()}
                                                                readonly={!viewModelPermissions.isContentEditable}
                                                                ownerGroupId={workspace.ownerGroupId}
                                                                viewModel={viewModel}
                                                                view={view}
                                                                viewPosition={viewPosition}
                                                                model={model}
                                                                isSelected={isSelected}
                                                                onSelectSingleView={handleSelectSingleView}
                                                                onToggleSelectedViews={handleToggleSelectedViews}
                                                                onDeselectAllViews={handleDeselectAllViews}
                                                                setMultiSelectionMode={setMultiSelectionMode}
                                                                currentUserProfile={currentUserProfile}
                                                                windowEventManager={windowEventManager}
                                                                isLinkableTarget={isLinkableTarget}
                                                                multiSelectionMode={multiSelectionMode}
                                                                onClickViewModelLink={handleOnClickViewModelLink}
                                                                modelCommentThreadId={modelCommentThreadId}
                                                                stickyModelContentsOperation={
                                                                    stickyModelContentsOperations[model.id]
                                                                }
                                                                creatingComment={creatingComments[model.id]}
                                                                onCreatingCommentAdd={handleCreatingCommentAdd}
                                                                onCreatingCommentDrag={handleCreatingCommentDrag}
                                                                onCreatingCommentDragEnd={handleCreatingCommentDragEnd}
                                                                onCreatingCommentCancel={handleCreatingCommentCancel}
                                                                onCreatingCommentSubmit={handleCreatingCommentSubmit}
                                                                focusView={focusView}
                                                                duplicateView={handleDuplicateView}
                                                                openDeleteModal={handleOpenDeleteViewsConfirmModal}
                                                                onDragStartView={handleDragViewsStart}
                                                                onDragView={handleDragViews}
                                                                onDragEndView={handleDragViewsEnd}
                                                                getViewRects={getViewRects}
                                                            />
                                                        </StickyModelContentsOperationContextProvider>
                                                    </ViewContextProvider>
                                                );
                                            })}

                                            {/* ビューのタイトルバーを表示するためのレイヤー */}
                                            <ViewTitleBarTeleporter.Target as="g" className="focus:outline-0" />

                                            {viewModelPermissions.isContentEditable &&
                                                selectedViewPosition &&
                                                selectedViewSize && (
                                                    <g transform={selectedViewPosition.toSVGTranslate()}>
                                                        {linker && (
                                                            <g
                                                                transform={new Point(
                                                                    -FLAME_SIZE / 2,
                                                                    -FLAME_SIZE / 2
                                                                ).toSVGTranslate()}
                                                            >
                                                                <LinkerCanvasView
                                                                    canvasSize={Rect.fromSize(FLAME_SIZE, FLAME_SIZE)}
                                                                    iconCircleSize={LINKER_ICON_CIRCLE_SIZE}
                                                                    linkerLineWidth={CONSISTENCY_LINK_LINE_WIDTH}
                                                                    linkerState={linker}
                                                                    offsetPosition={
                                                                        new Point(FLAME_SIZE / 2, FLAME_SIZE / 2)
                                                                    }
                                                                    onLinkerMove={handleLinkerMove}
                                                                    onLinkerEnd={handleLinkerEnd}
                                                                />
                                                            </g>
                                                        )}
                                                    </g>
                                                )}

                                            <PopupMenuTeleporter.Target as="g" className={'focus:outline-0'} />

                                            {mouseCursorPubSub.current && (
                                                <ClientMouseCursorView pubSub={mouseCursorPubSub.current} />
                                            )}
                                        </Frame>
                                    </svg>

                                    {!viewLoaded && !viewContents?.isEmpty() && (
                                        <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center">
                                            <div className={'flex flex-col items-center rounded-lg px-5 py-2'}>
                                                <FontAwesomeIcon
                                                    icon={faSpinner}
                                                    className="fa-spin text-3xl text-brand"
                                                />
                                                <div className="mt-2 text-center text-2xl font-light text-brand">
                                                    Now loading ...
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {viewLoaded && viewModelPermissions.isContentEditable && (
                                        <MemoizedViewModelToolbar
                                            readonly={!viewModelPermissions.isContentEditable}
                                            selectedViewIds={selectedViewIds}
                                            viewContents={
                                                viewContents ? viewContents : ViewCollectionContents.buildEmpty()
                                            }
                                            models={models}
                                            operations={stickyModelContentsOperations}
                                            handleCreatingCommentAdd={handleCreatingCommentAdd}
                                            onAddViewClicked={handleAddStickyView}
                                        />
                                    )}

                                    {showEmptyMessageModal && viewModelPermissions.isContentEditable && (
                                        <EmptyMessageModal
                                            onAddBasicView={handleAddStickyView}
                                            onClose={() => setShowEmptyMessageModal(false)}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </NewElementStyleProviderContext.Provider>
                </CommandManagerContext.Provider>
            </VisibleAreaCenterPointProvider>
            <ViewsDeleteConfirmModal
                isOpen={isOpenDeleteViewsConfirmModal}
                onClose={handleCloseDeleteViewsConfirmModal}
                onDelete={handleDeleteViews}
            />

            <EditingUsersLoader viewModelId={viewModel.id} />
            <ViewersLoader viewModelId={viewModel.id} />
        </Fragment>
    );
};
