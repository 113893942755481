import { ModelElementId } from '@view-model/domain/key';
import { CommandManager, CompositeCommand, ICommand } from '@model-framework/command';
import { ModelElementPositionMap } from '@view-model/domain/model';
import { DragManager, PositionSetRepository } from '@view-model/models/common/PositionSet';
import { ElementsMoveCommand } from '@view-model/command/basic-model/ElementsMoveCommand/ElementsMoveCommand';
import { StickyModelContentsOperation } from '@view-model/adapter';
import { ModelLayout } from '@view-model/models/sticky/layout';
import { StickyModelElementPositionMapRepository } from '@view-model/infrastructure/basic-model/StickyModelElementPositionMapRepository';
import { MultiSelectionMode } from '@user/pages/ViewModelPage';
import { DragContext } from '@model-framework/ui';
import { RTDBPath } from '@framework/repository';
import { ModelId, ViewId, ViewModelId } from '@schema-common/base';
import { Rect } from '@view-model/models/common/basic';

export class ElementDragManager {
    private startPositions: ModelElementPositionMap;
    private currentPositions: ModelElementPositionMap;
    private dragging: boolean;
    private readonly modelCommentDragManager: DragManager;
    private readonly descriptionPanelDragManager: DragManager;
    private readonly positionMapRepository: StickyModelElementPositionMapRepository;

    constructor(
        viewModelId: ViewModelId,
        modelId: ModelId,
        private readonly viewId: ViewId,
        private readonly commandManager: CommandManager,
        private readonly stickyModelContentsOperation: StickyModelContentsOperation,
        private readonly getViewRects: () => Record<ViewId, Rect>
    ) {
        this.startPositions = new ModelElementPositionMap();
        this.currentPositions = new ModelElementPositionMap();
        this.positionMapRepository = new StickyModelElementPositionMapRepository(viewModelId, modelId);
        this.modelCommentDragManager = new DragManager(
            new PositionSetRepository(RTDBPath.Comment.positionsPath(viewModelId, modelId))
        );
        this.descriptionPanelDragManager = new DragManager(
            new PositionSetRepository(RTDBPath.DescriptionPanel.positionsPath(viewModelId, modelId))
        );

        this.dragging = false;
    }

    /**
     * ドラッグ開始イベントハンドラ
     *
     * @param id ドラッグ対象のモデル要素ID（マウスポインタで掴んでいるモデル要素）
     * @param multiSelectionMode 複数選択モードかどうか
     */
    onDragStart(id: ModelElementId, multiSelectionMode: MultiSelectionMode): void {
        this.stickyModelContentsOperation.selectForDragStart(id, multiSelectionMode);
        const selectedIds = this.stickyModelContentsOperation.getSelectedIds();

        this.startPositions = this.stickyModelContentsOperation.selectedModelElementPositionMap();
        this.currentPositions = this.startPositions;

        this.descriptionPanelDragManager.dragStart(selectedIds).then();
        this.modelCommentDragManager.dragStart(selectedIds).then();

        if (this.dragging) {
            console.warn(
                'NodeDragManager.onDragStart() is called multiple times before onDragEnd(). \
                It may cause an unpredictable problem.'
            );
        }
        this.dragging = true;
    }

    async onDrag(context: DragContext): Promise<void> {
        this.currentPositions = this.startPositions.movePosition(
            context.x - context.dragStartX,
            context.y - context.dragStartY
        );

        await Promise.all([
            this.positionMapRepository.savePositions(this.currentPositions),
            this.modelCommentDragManager.drag(context.x, context.y, context.dragStartX, context.dragStartY),
            this.descriptionPanelDragManager.drag(context.x, context.y, context.dragStartX, context.dragStartY),
        ]);
    }

    async onDragEnd(): Promise<void> {
        if (!this.dragging) {
            // Example issue: https://github.com/levii/balus-app/issues/282
            console.warn(
                'NodeDragManager.onDragEnd() is called multiple times after single onDragStart(). \
                    It may cause an unpredictable problem.'
            );
        }
        this.dragging = false;

        // モデル要素(付箋、ゾーン) と、コメントの位置をレイアウトにスナップさせて、保存する
        this.currentPositions = this.currentPositions.snapToLayout(ModelLayout);
        await this.positionMapRepository.savePositions(this.currentPositions);

        // 全ての要素コマンドが null, undefined の場合には、 composeOptionalCommands() は null を返す
        const command = CompositeCommand.composeOptionalCommands(
            // モデル要素(付箋、ゾーン)の位置移動
            this.buildElementsMoveCommand(),
            // モデルコメントの位置移動
            await this.modelCommentDragManager.dragEnd(),
            // ゾーンに含まれる要素の更新 (親子関係の更新)
            await this.stickyModelContentsOperation.buildSelectedElementsDropCommand(),
            // 説明パネルの位置移動
            await this.descriptionPanelDragManager.dragEnd()
        );

        // ドラッグの開始前・後でいずれかの要素に差分があれば、 Undo できるように CommandManager 経由で実行する
        if (command) {
            this.commandManager.execute(command);
        }
    }

    /**
     * 付箋モデル要素(付箋、ゾーン)の位置移動コマンドを（必要な場合には）生成して返す
     *
     * @private
     */
    private buildElementsMoveCommand(): ICommand | undefined {
        // ドラッグ移動の開始前と後で位置が変化していなければ、コマンドは生成しない
        if (this.startPositions.isEqual(this.currentPositions)) {
            return;
        }

        return new ElementsMoveCommand(this.startPositions, this.currentPositions, this.positionMapRepository);
    }
}
