import { memo, useCallback } from 'react';
import { Position } from '@view-model/models/common/types/ui';
import { ModelCommentThread } from '../../domain';
import { DraggableContainer } from '@view-model/models/common/components/DraggableContainer';
import { CommentConstants } from '../../constants';
import { Size } from '@view-model/models/common/basic/Size';
import { ModelCommentDragHandler } from '../ModelCommentDragHandler';
import { ModelCommentThreadContent } from './ModelCommentThreadContent';
import { ModelCommentThreadId, ModelId, ViewModelId } from '@schema-common/base';
import { useCurrentUserPublicProfile } from '@user/PublicProfile';
import { DragContext } from '@model-framework/ui';

type Props = {
    viewModelId: ViewModelId;
    modelId: ModelId;
    position: Position;
    commentThread: ModelCommentThread;
    readonly: boolean;
    isSelected: boolean;
    onSizeChange(id: ModelCommentThreadId, newSize: Size): void;
    onDragStart(id: ModelCommentThreadId): void;
    onDrag(context: DragContext): void;
    onDragEnd(context: DragContext): void;
    onClick(id: ModelCommentThreadId): void;
};

export const ModelCommentThreadView = memo(
    ({
        viewModelId,
        modelId,
        position,
        commentThread,
        readonly,
        isSelected,
        onSizeChange,
        onDragStart,
        onDrag,
        onDragEnd,
        onClick,
    }: Props) => {
        const currentUserProfile = useCurrentUserPublicProfile();
        const threadId = commentThread.id;
        const isResolved = commentThread.isResolved();

        const handleDragStart = useCallback(() => {
            onDragStart(threadId);
        }, [onDragStart, threadId]);

        const handleClick = useCallback(() => {
            onClick(threadId);
        }, [onClick, threadId]);

        const { width, headerBarHeight } = CommentConstants.UISetting;

        if (!currentUserProfile) return null;

        return (
            <DraggableContainer
                position={position}
                onDragStart={handleDragStart}
                onDrag={onDrag}
                onDragEnd={onDragEnd}
                onClick={handleClick}
                dragHandleContent={
                    <ModelCommentDragHandler
                        width={width}
                        height={headerBarHeight}
                        movable={!readonly}
                        isResolved={isResolved}
                    />
                }
            >
                <ModelCommentThreadContent
                    viewModelId={viewModelId}
                    modelId={modelId}
                    commentThread={commentThread}
                    isSelected={isSelected}
                    onSizeChange={onSizeChange}
                    currentUserProfile={currentUserProfile}
                    readonly={readonly}
                />
            </DraggableContainer>
        );
    }
);
ModelCommentThreadView.displayName = 'ModelCommentThreadView';
