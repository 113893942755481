import { memo } from 'react';
import { ModelElementId } from '@view-model/domain/key';
import { ModelCommentContents } from './domain';
import { ModelCommentThreadView } from './components';
import { Size } from '@view-model/models/common/basic/Size';
import { SelectedItemSet } from '@view-model/models/common/basic';
import { ModelCommentThreadId, ModelId, ViewModelId } from '@schema-common/base';
import { DragContext } from '@model-framework/ui';

type Props = {
    viewModelId: ViewModelId;
    modelId: ModelId;
    commentContents: ModelCommentContents;
    onUpdateCommentSize(id: ModelCommentThreadId, newSize: Size): void;
    selectedItems: SelectedItemSet<ModelElementId>;
    readonly: boolean;
    onDragStart(id: ModelCommentThreadId): void;
    onDrag(context: DragContext): void;
    onDragEnd(context: DragContext): void;
    onClick(id: ModelCommentThreadId): void;
};

export const ModelCommentList = memo(
    ({
        viewModelId,
        modelId,
        commentContents,
        onUpdateCommentSize,
        selectedItems,
        readonly,
        onDragStart,
        onDrag,
        onDragEnd,
        onClick,
    }: Props) => {
        return (
            <g>
                {commentContents.map(([thread, position]) => {
                    const isSelected = selectedItems.isSelected(thread.id);
                    return (
                        <ModelCommentThreadView
                            key={thread.id}
                            viewModelId={viewModelId}
                            modelId={modelId}
                            commentThread={thread}
                            position={position}
                            onSizeChange={onUpdateCommentSize}
                            readonly={readonly}
                            isSelected={isSelected}
                            onDragStart={onDragStart}
                            onDrag={onDrag}
                            onDragEnd={onDragEnd}
                            onClick={onClick}
                        />
                    );
                })}
            </g>
        );
    }
);
ModelCommentList.displayName = 'ModelCommentList';
